import { FC, PropsWithChildren } from 'react';

export const ConsentValues = ['adPerformances', 'improveServices'] as const;
export type Consent = typeof ConsentValues[number];
export type Consents = Partial<Record<Consent, boolean>>;

export type ConsentListener = (consent: Consent, accepted: boolean) => void;

export interface ICookiePolicy {
  CookieProvider: FC<PropsWithChildren<{ onConsent: ConsentListener }>>;
  CookieComponent: FC;

  useCookieConsents: () => Consents;
  getCookieConsents: () => Consents;
  showCookiePolicy: null | (() => void);
}
