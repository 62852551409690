import React, {
  createContext,
  lazy,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { PersistKey } from '#technical/persist/persist';

import { Consents, ConsentValues, ICookiePolicy } from '../type';

const context = createContext<{
  shouldShowCookiesConsent: boolean;
  setConsent: (consent: boolean | null) => void;
  setHasIgnored: (ignored: boolean) => void;
}>({
  shouldShowCookiesConsent: false,
  setConsent: () => {},
  setHasIgnored: () => {},
});

const Provider: ICookiePolicy['CookieProvider'] = ({ children, onConsent }) => {
  const [hasIgnored, setHasIgnored] = useState(false);
  const [consent, setLocalConsent] = useLocalStorage<boolean | null>(
    PersistKey.COOKIES_CONSENT,
    null
  );

  const shouldShowCookiesConsent = useMemo(() => {
    if (hasIgnored) return false;
    return consent === null;
  }, [consent, hasIgnored]);

  const setConsent = useCallback(
    (newConsent: null | boolean) => {
      setLocalConsent(newConsent);
      if (newConsent !== null) {
        ConsentValues.forEach((k) => onConsent(k, newConsent));
      }
    },
    [onConsent, setLocalConsent]
  );

  return (
    <context.Provider
      value={{ shouldShowCookiesConsent, setConsent, setHasIgnored }}
    >
      {children}
    </context.Provider>
  );
};

const Cookies = lazy(() =>
  import('./Cookies').then((module) => ({ default: module.Cookies }))
);

const CookieComponent = () => {
  const { shouldShowCookiesConsent, setConsent, setHasIgnored } =
    useContext(context);

  if (!shouldShowCookiesConsent) {
    return null;
  }

  return (
    <Cookies
      onAccept={() => setConsent(true)}
      onDecline={() => setConsent(false)}
      onDismiss={() => setHasIgnored(true)}
    />
  );
};

function toConsents(consent: boolean) {
  return ConsentValues.reduce(
    (acc, key) => ({
      ...acc,
      [key]: consent,
    }),
    {}
  );
}

export const UCCookiePolicy: ICookiePolicy = {
  CookieProvider: Provider,
  CookieComponent,

  useCookieConsents: () => {
    const consent = useLocalStorage<boolean | null>(
      PersistKey.COOKIES_CONSENT,
      null
    );

    return useMemo<Consents>(() => toConsents(!!consent), [consent]);
  },
  getCookieConsents: () =>
    toConsents(localStorage.getItem(PersistKey.COOKIES_CONSENT) === 'true'),
  showCookiePolicy: null,
};
